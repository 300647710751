@import 'tailwindcss/base';
@import './styles.css';

@import 'tailwindcss/components';
@import './rc-checkbox.css';

@import 'tailwindcss/utilities';

@layer base {
  :root {
    /* HSL values */
    --color-kkm-green: 169deg 81% 58%;
    --color-kkm-darkgreen: 169deg 81% 45%;
    --color-kkm-lightgreen: 169deg 81% 70%;
  }
  @font-face {
    font-family: 'Gramatika';
    src: url('/fonts/Gramatika-Bold.woff2') format('woff2'),
      url('/fonts/Gramatika-Bold.woff') format('woff'),
      url('/fonts/Gramatika-Bold.ttf') format('truetype');
    font-weight: normal;
    font-display: auto;
  }

  a {
    @apply underline;
  }
}
